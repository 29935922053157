import React from "react";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb"
import CustomCarousel from "../components/CustomCarousel";
import { booksList,bookArticles } from "../data/books-list";
import Store from "../components/Store";

export default function Books() {
	const path = [
		{ label: "Inici", to: "/" },
		{ label: "Libres", to: "/libres" },
		{ label: "1900" },
	]

	return (
		<Layout>
			<div className="Books">
				<div className="Books-container">
					<Breadcrumb className="Books-breadcrumb" path={path} />
					<h2 className="Books-title">1900</h2>
				</div>
				<div className="Books-carousel">
					<CustomCarousel scrollInitialValue={7} slides={booksList} isRounded />
				</div>
				<div className="Books-container">
					<div className="Books-store">
						<Store productList={bookArticles} />
					</div>
				</div>
			</div>
		</Layout>
	);
}
