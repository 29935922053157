import React from 'react'

export default function RoundedItem(props) {
    const { image, text, refe, key } = props;
    return (
        <div className="RoundedItem" ref={refe} key={key}>
            <img className="RoundedItem-image" src={image} alt="" />
            <p className="RoundedItem-name">{text}</p>
        </div>
    )
}
