import React, { Fragment, useEffect, useState } from "react";
import CollectionItem from "../FeaturedCollection/FeaturedCollectionItem";
import RoundedItem from "../RoundedItem";

export default function CustomCarousel(props) {
	const { isCollection, isRounded, scrollInitialValue, slides } =
		props;

	const itemsPerPage = 1;
	const [scrollIndex, setscrollIndex] = useState(scrollInitialValue);

	const refs = slides.map(() => {
		return React.createRef();
	});

	useEffect(() => {
        const resizeListen = () => {
			// INIT: código modificado por JL para que no pete al hacer el build
			// let width = window.innerWidth;
			let width = 0;
			if( typeof window !== 'undefined' ) {
				width = window.innerWidth;
			} else {
				width = 1000;
			}
			// FINISH: codigo modificado por JL para que no pete al hacer el build
            if (width < 575) setscrollIndex(0);
            else setscrollIndex(scrollInitialValue);
        };
        resizeListen();
        window.addEventListener("resize", resizeListen);
        return () => {
            window.removeEventListener("resize", resizeListen);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

	useEffect(() => {
		let targetRef = refs[scrollIndex];
		if (
			targetRef &&
			targetRef.current &&
			targetRef.current.scrollIntoView
		) {
			targetRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "end"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollIndex]);

	const increaseIndex = () => {
		const currentItem = scrollIndex + itemsPerPage;
		if (slides.length === currentItem) {
			setscrollIndex(0);
			setTimeout(() => {
				setscrollIndex(scrollInitialValue)
			}, 500);
			return;
		}

		setscrollIndex(currentItem);
	};

	const renderBtn = () => {
		if (isRounded && slides.length < 9) {
			return <Fragment />
		}
		return (
			<button
				className="CustomCarousel-btn-right"
				onClick={increaseIndex}
			>
				<i className="i-custom-arrow" />
			</button>
		)
	}

	// const decreaseIndex = () => {
	// 	if (scrollIndex >= 1) {
	// 		setscrollIndex(scrollIndex - itemsPerPage);
	// 	}
	// };

	const renderCollection = () => {
		if (!isCollection) return <Fragment />;

		return (
			slides &&
			slides.map((item, index) => {
				return (
					<CollectionItem
						refe={refs[index]}
						key={item.id}
						image={item.image}
						text={item.text}
						link={item.link}
						backgroundColor={item.backgroundColor}
					/>
				);
			})
		);
	};

	const renderListRounded = () => {
		if (!isRounded) return <Fragment />;

		return (
			slides &&
			slides.map((item, index) => {
				return (
					<RoundedItem
						refe={refs[index]}
						key={item.id}
						image={item.image}
						text={item.text}
					/>
				);
			})
		);
	};

	const renderCarousel = () => {
		// INIT: código modificado por JL para que no pete al hacer el build
		// let width = window.innerWidth;
		let width = 0;
		if( typeof window !== 'undefined' ) {
			width = window.innerWidth;
		} else {
			width = 1000;
		}
		// FINISH: codigo modificado por JL para que no pete al hacer el build
		if (width < 575){
			if (slides.length < 8 && slides.length < 5) {
				return (
					<Fragment>
						{renderBtn()}
						<div className="CustomCarousel-container center">
							{renderCollection()}
							{renderListRounded()}
						</div>
					</Fragment>
				)
			}
			return (
				<Fragment>
					{renderBtn()}
					<div className="CustomCarousel-container start">
						{renderCollection()}
						{renderListRounded()}
					</div>
				</Fragment>
			)
		}
		if (width > 575){
			if (slides.length < 8 ) {
				return (
					<Fragment>
						{renderBtn()}
						<div className="CustomCarousel-container center">
							{renderCollection()}
							{renderListRounded()}
						</div>
					</Fragment>
				)
			}
			return (
				<Fragment>
					{renderBtn()}
					<div className="CustomCarousel-container start">
						{renderCollection()}
						{renderListRounded()}
					</div>
				</Fragment>
			)
		}
	}

	return (
		<div className="CustomCarousel">
			{renderCarousel()}
		</div>
	)
}
